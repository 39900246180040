<template>
  <ui-component-modal
    :modalTitle="'Yield playground'"
    modalSize="extra-large"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :hideSaveButton="true"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div>
        <div class="tabs is-boxed">
          <ul>
            <li
              v-for="(ys, index) in yieldSettingsList"
              :key="index"
              :class="{ 'is-active': activeIndex === index }"
              @click="setActiveYieldSettings(index, ys.Settings)"
            >
              <span>
                <a
                  >{{ ys.Name }}

                  <span
                    @click.stop="deleteTab(index)"
                    v-if="yieldSettingsList.length > 1"
                    class="icon has-text-danger"
                  >
                    <font-awesome-icon :icon="['fas', 'times']" />
                  </span>
                </a>
              </span>
            </li>
            <li>
              <a @click="addTab">
                <span class="icon has-text-success">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>
                <span>Add</span>
              </a>
            </li>
          </ul>
        </div>

        <yield-playground
          @yieldSettingsUpdated="yieldSettingsUpdated"
          :activeIndex="activeIndex"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

const YieldPlayground = () =>
  import('@/components/Yield/Playground/YieldPlayground')

export default {
  components: {
    'yield-playground': YieldPlayground,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    yieldSettings: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      activeIndex: 0,
      addCounter: 0,
      originalYieldSettings: null,
      yieldSettingsList: [],
    }
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsPlayground']),
  },

  created() {
    this.originalYieldSettings = JSON.parse(
      JSON.stringify(this.yieldSettingsPlayground)
    )
    this.addTab()
    this.setActiveYieldSettings(0, this.yieldSettingsPlayground)
  },

  beforeDestroy() {
    this.setYieldSettingsPlayground(null)
  },

  methods: {
    ...mapMutations('yieldStore', ['setYieldSettingsPlayground']),

    addTab() {
      this.addCounter = this.addCounter + 1

      this.yieldSettingsList.push({
        Name: 'Version ' + this.addCounter,
        Settings: JSON.parse(JSON.stringify(this.originalYieldSettings)),
      })

      let activeIndex = this.yieldSettingsList.length - 1
      this.setActiveYieldSettings(
        activeIndex,
        JSON.parse(JSON.stringify(this.originalYieldSettings))
      )
    },

    deleteTab(index) {
      this.yieldSettingsList.splice(index, 1)

      let newActiveIndex = index - 1 < 0 ? 0 : index - 1
      let ys = this.yieldSettingsList[newActiveIndex]

      this.setActiveYieldSettings(newActiveIndex, ys.Settings)
    },

    setActiveYieldSettings(index, ys) {
      this.activeIndex = index
      this.setYieldSettingsPlayground(JSON.parse(JSON.stringify(ys)))
    },

    yieldSettingsUpdated(yieldSettings) {
      let ys = this.yieldSettingsList[this.activeIndex]
      ys.Settings = yieldSettings

      this.$set(this.yieldSettingsList, this.activeIndex, ys)
    },
  },
}
</script>

<style></style>
